/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ConfigRevisionFiltersParamsSchema } from 'openapi-spec/api/routes/node-configs/schemas';

import {
  ConfigRevision,
  ConfigRevisionFilters,
  ConfigRevisionsSortableFields,
  GetConfigRevisionsData,
  GetConfigRevisionsResponse,
} from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedConfigRevisionsQueryParams = {
  params?: Omit<GetConfigRevisionsData, 'tenantId'>;
  type?: 'inMemory' | 'queryParams';
};

/**
 * Custom hook that fetches paginated config revisions with filtering, sorting and real-time updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated config revisions response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 * Note: tenantId is automatically pulled from the management plane state store
 */
export function usePaginatedConfigRevisionsQuery({ params, type }: UsePaginatedConfigRevisionsQueryParams) {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  return usePaginatedQuery<
    GetConfigRevisionsResponse,
    ConfigRevision,
    ConfigRevisionFilters,
    ConfigRevisionsSortableFields,
    GetConfigRevisionsData
  >({
    apiCall: mgmtPlaneAPIClient.nodeConfigs.getConfigRevisions.bind(mgmtPlaneAPIClient.nodeConfigs),
    filterTypes: zodSchemaToTypeMap(ConfigRevisionFiltersParamsSchema.shape),
    queryKey: 'config-revisions',
    realtimeEvents: [
      'node-config:updated',
      'node-config-revision:created',
      'node-config:assigned',
      'node-config:applied',
    ],
    params: {
      tenantId: selectedTenantId!,
      configId: params?.configId || '',
      ...params,
    },
    type,
    enabled: !!selectedTenantId,
  });
}
