/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  PaginationParams,
  buildPaginatedResponseSchema,
  buildResponseSchema,
  sortable,
} from '../../openapi';
import {
  NODE_ARCHITECTURE,
  NODE_OPERATING_SYSTEM,
  OPENAPI_NODE_CONFIG_STATUS,
  OPENAPI_NODE_CONNECTIVITY_STATE,
  OPENAPI_NODE_HEARTBEAT_STATE,
  OPENAPI_NODE_ID,
  OPENAPI_NODE_OPERATIONAL_STATE,
  OPENAPI_TENANT_ID,
  TYPE_UUID,
  USER_INFO,
} from '../../types';
import { tagName } from '../tags/schemas';
import { WorkspaceSchema } from '../workspaces/schemas';

export const NodeHostname = z.string().openapi({ example: 'apnyc-dc-001' });
export const NodeName = z.string().openapi({ example: 'Sales App Server' });
export const NodeVersion = z.string().optional().openapi({ example: '1.0.0' });

// base schema
export const SystemProfileSchema = z
  .object({
    'os.type': NODE_OPERATING_SYSTEM,
    'os.version': z.string().openapi({ example: '10.0.20348' }),
    'os.name': z.string().openapi({ example: 'Microsoft Windows Server 2022' }),
    'host.ip': z.string().openapi({ example: '10.0.0.1' }),
    'host.name': NodeHostname,
    'host.arch': NODE_ARCHITECTURE.optional(),
    'host.cpu.family': z.union([z.string(), z.number()]).optional(),
    'host.cpu.model.name': z.string().optional(),
    'host.mac': z.string().optional(),
    'node.modules': z.array(z.string()).optional(),
    'os.build.id': z.string().optional(),
    'os.description': z.string().optional(),
    'release.build.time': z.string().optional(),
    'release.commit': z.string().optional(),
    'release.id': z.string().optional(),
    'release.package_type': z.string().optional(),
    'release.version': z.string().optional(),
  })
  .optional()
  .openapi('SystemProfile');

export const NodeCredentialTimestamps = z
  .object({
    lastRotation: z.date().openapi({ format: 'date-time' }),
    notBefore: z.date().openapi({ format: 'date-time' }),
    notAfter: z.date().openapi({ format: 'date-time' }),
  })
  .required({
    lastRotation: true,
    notBefore: true,
    notAfter: true,
  })
  .openapi('NodeCredentialTimestamps');

export const NodeHeartbeatState = z.object({
  state: OPENAPI_NODE_HEARTBEAT_STATE,
  stateTime: z.date().openapi({ format: 'date-time' }),
});

export const NodeHeartbeatStates = z
  .object({
    config: NodeHeartbeatState,
    credential: NodeHeartbeatState,
  })
  .required({
    config: true,
    credential: true,
  })
  .openapi('NodeHeartbeatStates');

export const NodeConfigState = z.object({
  operationalState: OPENAPI_NODE_OPERATIONAL_STATE,
  status: OPENAPI_NODE_CONFIG_STATUS,
  statusDate: z.date().openapi({ format: 'date-time' }),
  instructionSetId: TYPE_UUID.optional(),
  configRevision: z
    .object({
      id: TYPE_UUID,
      revisionNumber: z.number().int(),
      configSchema: z.string().openapi({ example: 'v1.0.0' }),
      filename: z.string().openapi({ example: 'public-api-mimic.cue' }),
      description: z.string(),
    })
    .optional(),
  nodeConfig: z
    .object({
      id: TYPE_UUID,
      name: z.string(),
      latestRevisionNumber: z.number().int(),
      operatingSystem: NODE_OPERATING_SYSTEM,
    })
    .optional(),
  assignedBy: USER_INFO.optional(),
});

export const BaseNodeSchema = z.object({
  id: TYPE_UUID.openapi({
    'x-go-name': 'ID',
    example: '550e8400-e29b-41d4-a716-446655440000',
  }),
  tenantId: OPENAPI_TENANT_ID,
  workspace: WorkspaceSchema.optional(),
  name: NodeName,
  hostname: NodeHostname,
  isOperating: z.boolean(),
  operatingSystem: NODE_OPERATING_SYSTEM,
  connectivityState: OPENAPI_NODE_CONNECTIVITY_STATE,
  heartbeatStates: NodeHeartbeatStates.optional(),
  lastSeen: z.date().optional().openapi({ format: 'date-time' }),
  dateArchived: z.date().optional().openapi({ format: 'date-time' }),
  dateCreated: z.date().openapi({ format: 'date-time' }),
  tags: z.array(tagName),
  nodeVersion: NodeVersion,
  appliedRevisionState: NodeConfigState.extend({
    status: NodeConfigState.shape.status.optional(),
    statusDate: NodeConfigState.shape.statusDate.optional(),
  }).optional(),
  requestedRevisionState: NodeConfigState,
  dateUpdated: z.date().openapi({ format: 'date-time' }),
  description: z.string().optional().openapi({ example: 'Node Description' }),
  systemProfile: SystemProfileSchema,
  credentialTimestamps: NodeCredentialTimestamps.optional(),
  hallmarksCount: z.number().int(),
  deflectionsCount: z.number().int(),
  lastBaseline: z.date().optional().openapi({ format: 'date-time' }),
});

export const NodeSchema = BaseNodeSchema.openapi('Node');

export const NodeTagSchema = z
  .object({
    nodeId: OPENAPI_NODE_ID,
    tagId: z.string().uuid(),
    createdBy: z.string().uuid(),
    dateCreated: z.date().openapi({ format: 'date-time' }),
  })
  .openapi('NodeTag');

// mutation schemas
export const CreateNodeSchema = z
  .object({
    name: NodeName,
    description: z.string().optional().openapi({ example: 'Node Description' }),
  })
  .required({
    name: true,
    type: true,
  })
  .openapi('CreateNode');

export const PatchNodeSchema = z
  .object({
    name: NodeName.optional(),
    description: z.string().optional().openapi({ example: 'Node Description' }),
    workspaceId: TYPE_UUID.optional(),
  })
  .openapi('PatchNode');

// response schemas
export const NodeResponseSchema = buildResponseSchema(
  'NodeResponse',
  NodeSchema,
);

export const NodeTagResponseSchema = buildResponseSchema(
  'NodeTagResponse',
  NodeTagSchema,
);

export const NodeSortableFields = sortable('NodeSortableFields', [
  'hostname',
  'name',
  'appliedOperationalState',
  'isOperating',
  'connectivityState',
  'operatingSystem',
  'lastSeen',
  'dateCreated',
  'appliedConfiguration',
  'dateConfigRevisionApplied',
]);

export const NodeFilterParamsSchema = z
  .object({
    id: z.string().optional().openapi({
      example:
        '1e311dda-6ee1-40ce-aeef-36c9eb19023d,cdd36888-2f10-4171-aca3-e281d0c575cf',
    }),
    workspaceName: z.string().optional().openapi({ example: 'Marketing Team' }),
    hostname: NodeHostname.optional().openapi({ example: 'sales' }),
    operatingSystem: NODE_OPERATING_SYSTEM.optional(),
    name: NodeName.optional().openapi({ example: 'sales' }),
    assignedBy: z.string().optional().openapi({
      example:
        '1e311dda-6ee1-40ce-aeef-36c9eb19023d,cdd36888-2f10-4171-aca3-e281d0c575cf',
    }),
    tags: z.string().optional().openapi({ example: 'sales,prod' }),
    isArchived: z.boolean().optional().openapi({ example: true }),
    isOperating: z.boolean().optional().openapi({ example: true }),
    appliedNodeConfigId: TYPE_UUID.optional(),
    hasBaseline: z.boolean().optional().openapi({ example: true }),
  })
  .openapi('NodeFilters', { example: { name: 'sales' } });

export const PaginatedNodesResponseSchema = buildPaginatedResponseSchema(
  'PaginatedNodesResponse',
  NodeSchema,
  NodeFilterParamsSchema,
  NodeSortableFields,
);

export const NodeQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: NodeFilterParamsSchema.optional(),
    sort: NodeSortableFields.optional(),
  }),
);

export const NodeTagsPathParamsSchema = z.object({
  tenantId: OPENAPI_TENANT_ID,
  id: OPENAPI_NODE_ID,
  tagName: tagName,
});

export const OperationalStateChangeSchema = z
  .object({
    nodeId: OPENAPI_NODE_ID,
    operationalState: OPENAPI_NODE_OPERATIONAL_STATE,
    status: OPENAPI_NODE_CONFIG_STATUS,
    jobId: TYPE_UUID,
  })
  .required({
    nodeId: true,
    operationalState: true,
    status: true,
  })
  .openapi('OperationalStateChangeSchema');

export const OperationalStateChangeRequestResp = buildResponseSchema(
  'OperationalStateChangeResp',
  OperationalStateChangeSchema,
);

export const UpdateBaselineSchema = z
  .object({
    jobName: z.string().optional().openapi({ example: 'job name' }),
  })
  .openapi('UpdateBaselineRequest');

export const UpdateBaselineResponse = z
  .object({
    nodeId: OPENAPI_NODE_ID,
    jobId: TYPE_UUID,
  })
  .openapi('UpdateBaselineResponse');

export const UpdateBaselineResponseSchema = buildResponseSchema(
  'UpdateBaselineResponseSchema',
  UpdateBaselineResponse,
);

export const RequestedOperationalStateSpec = z
  .object({
    requestedOperationalState: OPENAPI_NODE_OPERATIONAL_STATE,
  })
  .required({
    requestedOperationalState: true,
  })
  .openapi('RequestedOperationalState');
