/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { WorkspacesFilterParamsSchema } from 'openapi-spec/api/routes/workspaces/schemas';

import {
  GetWorkspacesData,
  GetWorkspacesResponse,
  Workspace,
  WorkspacesFilter,
  WorkspacesSortableFields,
} from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedWorkspacesQueryParams = {
  params?: Omit<GetWorkspacesData, 'tenantId'>;
  type?: 'inMemory' | 'queryParams';
};

/**
 * Custom hook that fetches paginated workspaces with filtering, sorting and real-time updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated workspaces response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 * Note: tenantId is automatically pulled from the management plane state store
 */
export function usePaginatedWorkspacesQuery({ params, type }: UsePaginatedWorkspacesQueryParams) {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  return usePaginatedQuery<
    GetWorkspacesResponse,
    Workspace,
    WorkspacesFilter,
    WorkspacesSortableFields,
    GetWorkspacesData
  >({
    apiCall: mgmtPlaneAPIClient.workspaces.getWorkspaces.bind(mgmtPlaneAPIClient.workspaces),
    filterTypes: zodSchemaToTypeMap(WorkspacesFilterParamsSchema.shape),
    queryKey: 'workspaces',
    realtimeEvents: ['workspace:created', 'workspace:updated', 'workspace:deleted'],
    params: {
      tenantId: selectedTenantId!,
      ...params,
    },
    type,
    enabled: !!selectedTenantId,
  });
}
