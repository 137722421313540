/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { GuardedButton } from '@/components/GuardedButton';
import { PageHeader } from '@/components/PageHeader';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NewWorkspaceModal } from '@/pages/Workspaces/NewWorkspaceModal';
import { Container } from '@/primitives/Container';

export function WorkspacesHeader() {
  const { t } = useMimicTranslation('workspaces');

  const [newWorkspaceOpen, setNewWorkspaceOpen] = useState<boolean>(false);

  const onCancelModal = () => {
    setNewWorkspaceOpen(false);
  };

  return (
    <PageHeader title={t('title')} text={t('subtitle')} level={2}>
      <Container horizontal gap="sm">
        <GuardedButton
          requiredRole="editor"
          type="primary"
          dataTestId="new-workspace"
          onClick={() => setNewWorkspaceOpen(!newWorkspaceOpen)}
        >
          <PlusOutlined /> {t('newWorkspace')}
        </GuardedButton>
      </Container>
      <NewWorkspaceModal newWorkspaceOpen={newWorkspaceOpen} onCancelModal={onCancelModal} />
    </PageHeader>
  );
}
