/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Node, NodeFilters, PaginatedNodesResponse, Tag } from '@/client/';
import { GuardedButton } from '@/components/GuardedButton';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { NodesColumns } from '@/pages/Nodes/getNodesColumns';
import { NodeFilter } from '@/pages/Nodes/NodesHeader';
import { NodesTable } from '@/pages/Nodes/NodesTable';
import { TableProps } from '@/primitives/Table';
import { setJobWizardSelectedNodes } from '@/state/jobWizardStore';
import { UpdateParams } from '@/utils/params';

export type NodesListProps = {
  tenantID: string;
  tags: Tag[] | undefined;
  onParamsChange: UpdateParams<Node, Partial<NodeFilters>>;
  nodesState: NodeFilter.CONFIGURED | NodeFilter.PENDING;
  nodesData: PaginatedNodesResponse;
  onAssignConfiguration: (node: Node) => void;
  getNodeConfigurationPath: (nodeConfigId: string) => string;
};

const pendingColumns: NodesColumns[] = [
  'hostname',
  'lastSeen',
  'operatingSystem',
  'dateCreated',
  'assignConfiguration',
];

const configuredColumns: NodesColumns[] = [
  'hostname',
  'workspaceName',
  'tags',
  'appliedOperationalState',
  'connectivityState',
  'operatingSystem',
  'lastSeen',
  'appliedConfiguration',
];

export function NodesList({
  tenantID,
  tags,
  onParamsChange,
  nodesState,
  nodesData,
  onAssignConfiguration,
  getNodeConfigurationPath,
}: NodesListProps) {
  const { t } = useMimicTranslation('nodes');
  const navigate = useNavigate();
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const [noSystemProfileInfo, setNoSystemProfileInfo] = useState<boolean>(false);
  const [sameOs, setSameOs] = useState<boolean>(true);
  const [sameWorkspace, setSameWorkspace] = useState<boolean>(true);
  const [showDifferentOsError, setShowDifferentOsError] = useState<boolean | undefined>();
  const [showDifferentWorkspaceError, setShowDifferentWorkspaceError] = useState<boolean | undefined>();

  const visibleColumns = nodesState === NodeFilter.CONFIGURED ? configuredColumns : pendingColumns;

  const rowSelection: TableProps<Node>['rowSelection'] = {
    type: 'checkbox',
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedNodeIds,
    onChange: (_selectedKeys: React.Key[], selectedRows: Node[]) => {
      const selectedOses = selectedRows.map((node) => node.operatingSystem);
      const selectedWorkspaces = selectedRows.map((node) => node.workspace?.id);
      const uniqueOses = new Set(selectedOses);
      const uniqueWorkspaces = new Set(selectedWorkspaces);
      const newSameOsVal = uniqueOses.size === 1 || selectedRows.length === 0;
      const newSameWorkspaceVal = uniqueWorkspaces.size === 1 || selectedRows.length === 0;
      setSameOs(newSameOsVal);
      setSameWorkspace(newSameWorkspaceVal);
      const selectedIds = selectedRows.map((node) => node.id);
      setSelectedNodeIds(selectedIds);
      setJobWizardSelectedNodes(selectedRows);
      setNoSystemProfileInfo(selectedRows.some((node) => !node.operatingSystem));
      if (newSameOsVal) {
        setShowDifferentOsError(false);
      }
      if (newSameWorkspaceVal) {
        setShowDifferentWorkspaceError(false);
      }
    },
    getCheckboxProps: (node: Node) => ({
      name: node.name,
    }),
    renderCell: (_value, node: Node, _, originNode) => {
      // Clone the original node and add data-testid
      if (React.isValidElement(originNode)) {
        return React.cloneElement(originNode, {
          ...originNode.props,
          'data-testid': `checkbox-${node.id}`,
        } as React.HTMLAttributes<HTMLElement>);
      }
      return originNode;
    },
  };

  const noSystemProfileInfoMessage = noSystemProfileInfo ? t('banner.noSystemProfileInfo') : undefined;
  const selectionWorkspaceWarning = !sameWorkspace ? t('banner.differentWorkspaceWarning') : undefined;
  const selectionWarning = !sameOs ? t('banner.differentOSWarning') : undefined;
  const selectionError = showDifferentOsError ? t('banner.differentOSError') : undefined;
  const selectionWorkspaceError = showDifferentWorkspaceError ? t('banner.differentWorkspaceError') : undefined;

  const warning = selectionWarning || selectionWorkspaceWarning;
  const error = selectionError || selectionWorkspaceError;

  return (
    <NodesTable
      nodesData={nodesData}
      onParamsChange={onParamsChange}
      columns={visibleColumns}
      tags={tags}
      tenantID={tenantID}
      getNodeConfigurationPath={getNodeConfigurationPath}
      onAssignConfiguration={onAssignConfiguration}
      selectedNodeIds={selectedNodeIds}
      noSystemProfileInfoMessage={noSystemProfileInfoMessage}
      selectionWarning={warning}
      selectionError={error}
      rowSelection={rowSelection}
      primaryAction={
        <GuardedButton
          requiredRole="superadmin"
          type="primary"
          style={{ height: '36px' }}
          onClick={() => {
            if (!sameOs) {
              setShowDifferentOsError(true);
              return;
            }
            if (!sameWorkspace) {
              setShowDifferentWorkspaceError(true);
              return;
            }
            if (noSystemProfileInfo) {
              return;
            }
            navigate(`/tenants/${tenantID}/job-configure-nodes`);
          }}
        >
          {t('configureNodes')}
        </GuardedButton>
      }
    />
  );
}
