/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Form, Modal } from 'antd';
import { useState } from 'react';

import { ApiError, Workspace } from '@/client';
import { validationErrors } from '@/components/Forms/validationErrors';
import { useMessage } from '@/hooks/message';
import { useUpdateWorkspaceMutation } from '@/hooks/mutation/useUpdateWorkspaceMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { WorkspaceForm, WorkspaceFormFields } from '@/pages/Workspaces/WorkspaceForm';
import { Container } from '@/primitives/Container';
import { Divider } from '@/primitives/Divider';
import { Title } from '@/primitives/Title';
import { getValidationMessage } from '@/utils/errors';

export type UpdateWorkspaceModalProps = {
  workspace: Workspace | undefined;
  onClose: () => void;
};

export function UpdateWorkspaceModal({ workspace, onClose }: UpdateWorkspaceModalProps) {
  const { t } = useMimicTranslation('workspaces');
  const [form] = Form.useForm<WorkspaceFormFields>();

  const [message] = useMessage();
  const [updateIsPending, setUpdateIsPending] = useState(false);

  const { mutate: updateWorkspaceMutation } = useUpdateWorkspaceMutation({
    onError: (error, _) => {
      setUpdateIsPending(false);
      if (error instanceof ApiError) {
        const newMessage = getValidationMessage(error, 'name');
        form.setFields(validationErrors<Partial<WorkspaceFormFields>>({ name: newMessage }));
      } else {
        message.error(t('workspaceUpdateError', workspace));
      }
    },
    onSuccess: (updatedWorkspace) => {
      message.success(t('workspaceUpdated', { newName: updatedWorkspace.data.name, oldName: workspace?.name }));
      closeEditDialog();
    },
    onMutate: () => {
      setUpdateIsPending(true);
    },
  });

  const onWorkspaceUpdate = async () => {
    if (workspace) {
      try {
        await form.validateFields();

        const name = form.getFieldValue('name');
        const badgeColor = form.getFieldValue('badgeColor');
        const workspaceId = workspace.id;
        const patchPayload = { name, badgeColor };
        updateWorkspaceMutation({ id: workspaceId, requestBody: patchPayload });
      } catch {
        // no op
      }
    }
  };

  const closeEditDialog = () => {
    setUpdateIsPending(false);
    onClose();
  };

  const onCancelEditWorkspace = () => {
    closeEditDialog();
  };

  return (
    <Form.Provider onFormFinish={onWorkspaceUpdate}>
      <Modal
        key={workspace?.id}
        open={Boolean(workspace)}
        onCancel={onCancelEditWorkspace}
        onOk={onWorkspaceUpdate}
        cancelText={t('editWorkspace.cancelButton')}
        cancelButtonProps={{ disabled: updateIsPending }}
        okText={t('editWorkspace.submitButton')}
        okButtonProps={{ loading: updateIsPending, htmlType: 'submit' }}
      >
        <Container gap="lg" full style={{ backgroundColor: 'transparent' }}>
          <Title level={4} style={{ marginTop: '32px' }}>
            {t('editWorkspace.title')}
          </Title>
          <WorkspaceForm workspace={workspace} form={form} loading={updateIsPending} />
          <Divider />
        </Container>
      </Modal>
    </Form.Provider>
  );
}
