/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { WorkspaceMemberFilterParamsSchema } from 'openapi-spec/api/routes/workspaces/schemas';

import {
  GetWorkspaceMembersData,
  GetWorkspaceMembersResponse,
  WorkspaceMember,
  WorkspaceMemberFilter,
  WorkspaceMembersSortableFields,
} from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedWorkspaceMembersQueryParams = {
  params: Omit<GetWorkspaceMembersData, 'tenantId'>;
  type?: 'inMemory' | 'queryParams';
};

/**
 * Custom hook that fetches paginated workspaceMembers with filtering, sorting and real-time updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated workspaceMembers response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 * Note: tenantId is automatically pulled from the management plane state store
 */
export function usePaginatedWorkspaceMembersQuery({ params, type }: UsePaginatedWorkspaceMembersQueryParams) {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  return usePaginatedQuery<
    GetWorkspaceMembersResponse,
    WorkspaceMember,
    WorkspaceMemberFilter,
    WorkspaceMembersSortableFields,
    GetWorkspaceMembersData
  >({
    apiCall: mgmtPlaneAPIClient.workspaces.getWorkspaceMembers.bind(mgmtPlaneAPIClient.workspaces),
    filterTypes: zodSchemaToTypeMap(WorkspaceMemberFilterParamsSchema.shape),
    queryKey: 'workspaceMembers',
    realtimeEvents: ['workspace-membership:updated', 'workspace:updated'],
    params: {
      tenantId: selectedTenantId!,
      ...params,
    },
    type,
    enabled: !!selectedTenantId,
  });
}
