/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { UserFilterParamsSchema } from 'openapi-spec/api/routes/users/schemas';

import { GetJobNodesData, GetJobNodesResponse, JobNode, JobNodeFilters, JobNodeSortableFields } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedJobNodesQueryParams = {
  params?: GetJobNodesData;
  type?: 'inMemory' | 'queryParams';
};

/**
 * Custom hook that fetches paginated job nodes with filtering, sorting and real-time updates.
 * Combines React Query for data fetching with WebSocket subscriptions for real-time updates.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated job nodes response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 * Note: tenantId is automatically pulled from the management plane state store
 */
export function usePaginatedJobNodesQuery({ params, type }: UsePaginatedJobNodesQueryParams) {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);

  return usePaginatedQuery<GetJobNodesResponse, JobNode, JobNodeFilters, JobNodeSortableFields, GetJobNodesData>({
    apiCall: mgmtPlaneAPIClient.jobs.getJobNodes.bind(mgmtPlaneAPIClient.jobs),
    filterTypes: zodSchemaToTypeMap(UserFilterParamsSchema.shape),
    queryKey: 'job-nodes',
    realtimeEvents: [
      'node:operational-state-changed',
      'node-config:assigned',
      'job:updated',
      'job:completed',
      'job:timedout',
    ],
    params: {
      tenantId: selectedTenantId!,
      ...params,
    } as GetJobNodesData,
    type,
    enabled: !!selectedTenantId,
  });
}
