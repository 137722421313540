/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Form, FormInstance } from 'antd';
import { useState } from 'react';

import { Workspace } from '@/client';
import { ColorPicker, colorPickerOptions } from '@/components/ColorPicker';
import { ValidationError } from '@/components/Forms/ValidationError';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Input } from '@/primitives/Input';
import { WorkspaceBadge } from '@/primitives/WorkspaceBadge';

export type WorkspaceFormProps = {
  workspace?: Workspace;
  loading: boolean;
  form: FormInstance<WorkspaceFormFields>;
};

export type WorkspaceFormFields = {
  name: string;
  badgeColor: string;
};

export function WorkspaceForm({ workspace, loading, form }: WorkspaceFormProps) {
  const { t } = useMimicTranslation('workspaces');
  const [badgeColor, setBadgeColor] = useState<string>(workspace?.badgeColor || colorPickerOptions[0]);
  const [newWorkspaceName, setNewWorkspaceName] = useState<string | undefined>(workspace?.name || '');
  const maxLength = 30;

  return (
    <Form
      form={form}
      autoComplete="off"
      initialValues={{ name: workspace?.name, badgeColor }}
      layout="vertical"
      requiredMark={requiredMarkAfter}
      data-testid="workspace-form"
      validateTrigger="onBlur"
    >
      <Form.Item<WorkspaceFormFields>
        label={t('addWorkspace.workspaceName')}
        status="error"
        name="name"
        rules={[
          { required: true, message: <ValidationError>{t('workspaceForm.nameRequired')}</ValidationError> },
          {
            max: maxLength,
            message: <ValidationError>{t('workspaceForm.nameMaxLength', { maxLength })}</ValidationError>,
          },
        ]}
      >
        <Input
          autoFocus
          disabled={loading}
          placeholder={t('addWorkspace.namePlaceholder')}
          onChange={(event) => {
            setNewWorkspaceName(event.target.value);
            form.setFieldValue('name', event.target.value);
          }}
          allowClear
          dataTestId="workspace-name-input"
          count={{
            max: maxLength,
          }}
        />
      </Form.Item>
      <Form.Item<WorkspaceFormFields> label={t('addWorkspace.badgeColor')} status="error" name="badgeColor">
        <ColorPicker color={badgeColor} onChange={setBadgeColor} />
      </Form.Item>
      <Form.Item<WorkspaceFormFields> label={t('addWorkspace.badgePreview')}>
        <WorkspaceBadge badgeColor={badgeColor}>{newWorkspaceName || t('addWorkspace.workspaceName')}</WorkspaceBadge>
      </Form.Item>
    </Form>
  );
}

const requiredMarkAfter = (label: React.ReactNode) => label;
