/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Form, Modal } from 'antd';
import { useState } from 'react';

import { useMessage } from '@/hooks/message';
import { useNewWorkspaceMutation } from '@/hooks/mutation/useNewWorkspaceMutation';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { WorkspaceForm, WorkspaceFormFields } from '@/pages/Workspaces/WorkspaceForm';

type NewWorkspaceModalProps = {
  newWorkspaceOpen: boolean;
  onCancelModal: () => void;
};

export function NewWorkspaceModal({ newWorkspaceOpen, onCancelModal }: NewWorkspaceModalProps) {
  const { t } = useMimicTranslation('workspaces');
  const [form] = Form.useForm<WorkspaceFormFields>();
  const [creationIsPending, setCreationIsPending] = useState<boolean>(false);
  const [message] = useMessage();

  const { mutate: createNewWorkspace } = useNewWorkspaceMutation({
    onError: (_, workspace) => {
      message.error(t('newWorkspaceCreatedError', workspace));
      setCreationIsPending(false);
    },
    onSuccess: (_, deletedWorkspace) => {
      message.success(t('newWorkspaceCreated', deletedWorkspace));
      closeDeleteDialog();
      setCreationIsPending(false);
    },
    onMutate: () => {
      setCreationIsPending(true);
    },
  });

  const closeDeleteDialog = () => {
    form.resetFields();
    onCancelModal();
  };

  const onFormFinish = async () => {
    try {
      await form.validateFields();
      const name = form.getFieldValue('name');
      const badgeColor = form.getFieldValue('badgeColor');
      createNewWorkspace({ name, badgeColor });
      onCancelModal();
    } catch (error) {
      // no op
    }
  };

  return (
    newWorkspaceOpen && (
      <Modal
        title={t('addWorkspace.modal')}
        open={newWorkspaceOpen}
        onCancel={closeDeleteDialog}
        onOk={onFormFinish}
        cancelText={t('workspaceForm.cancel')}
        cancelButtonProps={{ disabled: creationIsPending }}
        okText={t('workspaceForm.ok')}
        okButtonProps={{ loading: creationIsPending }}
      >
        <WorkspaceForm loading={creationIsPending} form={form} />
      </Modal>
    )
  );
}
