/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { useState } from 'react';
import './ColorPicker.css';

// eslint-disable-next-line react-refresh/only-export-components
export const colorPickerOptions = [
  '#efe5b0',
  '#e6d690',
  '#f8c9a0',
  '#ffbe94',
  '#f0a8a8',
  '#e8b4b8',
  '#e6a9bc',
  '#d7b9d5',
  '#c8a4d4',
  '#b5c0e0',
  '#9dc5d4',
  '#97d2d2',
  '#b8d0b9',
  '#aad5aa',
  '#a0e5a0',
  '#dddddd',
];

export type ColorPickerProps = {
  color?: string;
  onChange: (color: string) => void;
};

export function ColorPicker({ color, onChange }: ColorPickerProps) {
  const [selectedColor, setSelectedColor] = useState<string | undefined>(color);

  const onChangeColor = (newColor: string) => {
    setSelectedColor(newColor);
    onChange(newColor);
  };

  return (
    <div data-testid="color-picker" style={{ marginTop: '8px' }} role="group" aria-label="Color picker">
      {colorPickerOptions.map((c) => {
        return (
          <button
            key={c}
            type="button"
            data-testid="color-option"
            onClick={() => onChangeColor(c)}
            style={{ backgroundColor: c }}
            className={`color-option color-option-${c} ${selectedColor === c ? 'selected' : ''}`}
            aria-label={`Select color ${c}`}
            aria-pressed={selectedColor === c}
          />
        );
      })}
    </div>
  );
}
